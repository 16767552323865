import useChapterMediaToken from '@app/hooks/useChapterMediaToken';
import useNextChapter, {useNextChapterId} from '@app/hooks/useNextChapter';
import {getUserId, getUserPremium, getUserToken} from '@app/stores/auth';
import styles from '@app/styles/views/VideoVOD.module.sass';
import NavItem, {focus, KEY_NAV} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import MediaPlayer from '@components/VideoPlayer';
import VideoRestricted from '@components/VideoRestricted';
import {useCallback} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {playbackLog} from '@app/api/playbackLog';
import moment from 'moment';
import {getCurrentTime} from '@app/stores/player';
import {ISGLOBAL} from '@app/constants';

export default function VideoVOD() {
  const media = useChapterMediaToken();
  const userPremium = useSelector(getUserPremium);
  const userId = useSelector(getUserId);
  const [template, setTemplate] = useState(<></>);
  const isRendered = useRef(false);
  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const params = useParams();
  const nextChapterId = useNextChapterId();
  const nextChapter = useNextChapter();
  const ref = useRef();
  const mountTimeout = useRef(0);
  const token = useSelector(getUserToken);
  const startTime = useRef(
    moment.utc().subtract(5, 'hours').format('YYYY-MM-DD H:m:s'),
  );
  const currentTime = useSelector(getCurrentTime);

  function getADURL(url, opt) {
    let config = {
      ...opt,
    };

    if (!url) {
      return url;
    }

    config.correlator = new Date().getTime();
    config.description_url = encodeURIComponent(
      'https://tvgo.americatv.com.pe',
    );

    let urlBases = url.split('?');
    let urlParams = (urlBases[1] || '').split('&');
    let params = [];

    urlParams.forEach(item => {
      let pars = item.split('=');
      let key = pars[0];
      let value = pars[1];

      if (config[key]) {
        value = config[key];
      }
      params.push(`${key}=${value}`);
    });

    let result = urlBases[0] + '?' + params.join('&');

    if (result.indexOf('vid_d') === -1 && config?.vid_d) {
      result += '&vid_d=' + parseInt(config.vid_d, 10);
    }
    if (result.indexOf('description_url') === -1 && config?.description_url) {
      result += '&description_url=' + config.description_url;
    }
    if (result.indexOf('rdid') === -1 && config?.rdid) {
      result += '&rdid=' + config.rdid;
    }
    if (result.indexOf('is_lat') === -1 && config?.is_lat !== undefined) {
      result += '&is_lat=' + config.is_lat;
    }

    return result;
  }

  const onNextChapterHandler = useCallback(() => {
    media.getData({
      categoryId: params.category_id,
      showId: media.data?.showData?.id,
      seasonId: media.data?.seasonData?.id,
      chapterId: nextChapter.data.id,
    });
    setIsBtnVisible(nextChapter.data.id === nextChapterId.data ? false : true);
  }, [nextChapter.data]);

  useEffect(() => {
    if (nextChapterId.data) {
      nextChapter.getData({
        chapterId: nextChapterId.data,
      });
      setIsBtnVisible(true);
    } else {
      setIsBtnVisible(false);
    }
  }, [nextChapterId.data]);

  useEffect(() => {
    const m = media.data || {};
    const screenTitle = [];

    let access = false;

    if (userPremium) {
      access = true;
    } else if (ISGLOBAL) {
      access = false;
    } else {
      access = userId && m?.chapterData?.free;
    }

    if (m?.videoData?.mediaToken && access) {
      if (m?.chapterData?.title) {
        screenTitle.push(m.chapterData.title);
      }
      if (m?.seasonData?.title) {
        screenTitle.push(m.seasonData.title);
      }
      if (m?.showData?.title) {
        screenTitle.push(m.showData.title);
      }

      nextChapterId.getData({
        showId: m?.showData?.id,
        seasonId: m?.seasonData?.id,
        chapterId: m?.chapterData?.id,
        chaptersSort: 'SORT_ASC',
      });
      setTemplate(
        <MediaPlayer
          mediaId={m?.videoData?.mediaId}
          mediaToken={m?.videoData?.mediaToken}
          title={screenTitle.join(' - ')}
          duration={m?.duration}
          currentTime={
            m?.videoData?.user_progress_percentage < 100
              ? m?.videoData?.user_progress_seconds
              : null
          }
          vastUrl={
            userPremium
              ? null
              : getADURL(m?.vastUrl, {
                  vid_d: m?.duration,
                })
          }
          // nextChapter={nextChapterSlug.data ? true : false}
          nextChapter={isBtnVisible}
          onNextChapter={onNextChapterHandler}
        />,
      );
    } else {
      setTemplate(<VideoRestricted />);
    }
  }, [userId, media.data, userPremium, nextChapter.data]);

  useEffect(() => {
    if (!isRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);
      if (current) {
        focus(current);
      }
      isRendered.current = true;

      media.getData({
        categoryId: params.category_id,
        showId: params.show_id,
        seasonId: params.season_id,
        chapterId: params.chapter_id,
      });
    }
  }, []);

  useEffect(() => {
    clearTimeout(mountTimeout.current);

    return () => {
      mountTimeout.current = setTimeout(() => {
        const config = {
          start: startTime.current,
          end: moment.utc().subtract(5, 'hours').toDate().getTime(),
          duration: parseInt(currentTime, 10),
          media_id: media.data?.videoData?.mediaId,
        };

        playbackLog(config, token);
      }, 100);
    };
  }, [token, media.data, currentTime]);

  return (
    <div ref={ref} className={styles.main}>
      {media.loading && (
        <>
          <Loading />
          <NavItem id={'defaultfocus'} />
        </>
      )}
      {!media.loading && template}
    </div>
  );
}
