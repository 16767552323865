import styles from '@app/styles/components/HomeChannels24Hours.module.sass';
import useScrollDrag from '@app/hooks/useScrollDrag';
import HomeChannel24hoursCard from './HomeChannel24hoursCard';
import {useCallback, useEffect, useRef, useState} from 'react';
import useChannels from '@app/hooks/useChannels';
import moment from 'moment';
import {KEY_NAV, keyNavAttribute, keyNavSelectors} from './KeyNavItem';

const AFHS_ID = '657fbe15e3ba9363bb07c6b4';
const NOVELAS_ID = '65fc43190d37be36e84e931f';

/**
 * A React component that displays a section with live channels for 24 hours.
 *
 * @param {object} props - The component's props.
 * @param {string} props.id - The ID of the section.
 * @param {function} props.onClick - A callback function to handle click events.
 * @param {object} props.keynavscrolltoel - An object with key navigation properties.
 * @param {object} props.keynavup - An object with key navigation properties.
 * @param {object} props.keynavdown - An object with key navigation properties.
 * @return {JSX.Element} The JSX element representing the component.
 */
export default function HomeChannels24Hours(props) {
  const slider = useRef(null);
  useScrollDrag(slider);
  const channels = useChannels();
  const [AFHData, setAFHData] = useState(null);
  const [novelasData, setNovelasData] = useState(null);

  const getChannelSchedule = useCallback(async id => {
    try {
      const data = await channels.getSchedule(id);
      let current_episode = data.find(item => item.is_current);

      if (current_episode) {
        current_episode.state = getEpisodeState({
          dateEnd: current_episode.date_end,
          dateStart: current_episode.date_start,
        });
      }

      if (current_episode?.state?.isCurrentChapterEnded) {
        current_episode = data[0];
      }

      return current_episode;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getEpisodeState = useCallback(({dateEnd, dateStart}) => {
    const dateEndMoment = moment.utc(dateEnd);

    const currentDateMoment = moment().utc();
    let restMinutes = dateEndMoment.diff(currentDateMoment, 'minutes');

    const t =
      dateEndMoment.toDate().getTime() -
      moment.utc(dateStart).toDate().getTime();

    const f =
      currentDateMoment.toDate().getTime() -
      moment.utc(dateStart).toDate().getTime();

    let p = (f * 100) / t;

    const isCurrentChapterEnded = currentDateMoment.isAfter(dateEndMoment);

    if (restMinutes < 0) {
      restMinutes = 0;
    }

    if (p > 100) {
      p = 100;
    }

    return {
      percent: p,
      restMinutes,
      isCurrentChapterEnded,
    };
  }, []);

  const getAFHCurrentEpisode = useCallback(async () => {
    try {
      const data = await getChannelSchedule(AFHS_ID);
      if (data) {
        setAFHData(data);
      } else {
        setAFHData(null);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getNovelasCurrentEpisode = useCallback(async () => {
    try {
      const data = await getChannelSchedule(NOVELAS_ID);
      if (data) {
        setNovelasData(data);
      } else {
        setNovelasData(null);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const updateEpisodeState = useCallback(async () => {
    try {
      if (AFHData) {
        const a = {...AFHData};
        a.state = getEpisodeState({
          dateEnd: a.date_end,
          dateStart: a.date_start,
        });
        if (a.state?.percent === 100) {
          getAFHCurrentEpisode();
        }
        setAFHData(a);
      } else {
        getAFHCurrentEpisode();
      }

      if (novelasData) {
        const b = {...novelasData};
        b.state = getEpisodeState({
          dateEnd: b.date_end,
          dateStart: b.date_start,
        });
        if (b.state?.percent === 100) {
          getNovelasCurrentEpisode();
        }
        setNovelasData(b);
      } else {
        getNovelasCurrentEpisode();
      }
    } catch (e) {
      console.log(e);
    }
  }, [AFHData, novelasData, getEpisodeState]);

  useEffect(() => {
    // Al fondo hay sitio
    getAFHCurrentEpisode();
    // novelas
    getNovelasCurrentEpisode();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateEpisodeState();
    }, 60000);

    return () => clearInterval(interval);
  }, [AFHData, novelasData]);

  return (
    <>
      {(AFHData || novelasData) && (
        <section
          id={props.id}
          className={styles.main}
          style={{
            backgroundImage: "url('images/bg-channels-24-hours.png')",
          }}>
          <h2 className={styles.title}>
            Canales en vivo <span>las 24 horas</span>
          </h2>
          <div
            id="24hoursList"
            className={styles.list}
            ref={slider}
            {...keyNavAttribute(KEY_NAV.PARENT)}>
            {AFHData && (
              <HomeChannel24hoursCard
                channel={'AFHS'}
                img={AFHData?.show_info?.episode?.images[0]?.path}
                chapterNumber={AFHData?.show_info?.episode?.title}
                currentTime={AFHData?.state.restMinutes}
                progress={AFHData?.state.percent}
                title={'Al fondo hay sitio'}
                onClick={() => {
                  if (typeof props.onClick === 'function') {
                    props.onClick({
                      id: AFHS_ID,
                    });
                  }
                }}
                keynavscrolltoel={props.keynavscrolltoel}
                keynavup={props.keynavup}
                keynavdown={props.keynavdown}
                keynavleft={keyNavSelectors()
                  .querySelector(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavright={keyNavSelectors().steps('nextSibling').build()}
              />
            )}
            {novelasData && (
              <HomeChannel24hoursCard
                channel={'Novelas y Series'}
                img={novelasData?.show_info?.episode?.images[0]?.path}
                chapterNumber={novelasData?.show_info?.episode?.title}
                currentTime={novelasData?.state.restMinutes}
                progress={novelasData?.state.percent}
                title={novelasData?.show_info?.showId?.title}
                onClick={() => {
                  if (typeof props.onClick === 'function') {
                    props.onClick({
                      id: NOVELAS_ID,
                    });
                  }
                }}
                keynavscrolltoel={props.keynavscrolltoel}
                keynavup={props.keynavup}
                keynavdown={props.keynavdown}
                keynavleft={keyNavSelectors()
                  .steps('previousSibling')
                  .querySelector(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
}
