import styles from '@app/styles/components/ChapterChannel.module.sass';
import NavItem from './KeyNavItem';
import {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import 'moment/locale/es';

/**
 * Renders a ChapterChannel component based on the provided chapter data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.chapter - The chapter data.
 * @param {boolean} props.chapter.isLive - Indicates if the chapter is live.
 * @param {string} props.chapter.title - The title of the chapter.
 * @param {number} props.chapter.chapterNumber - The chapter number.
 * @param {string} props.chapter.date - The date of the chapter.
 * @return {JSX.Element} The rendered ChapterChannel component.
 */
export default function ChapterChannel({chapter, updateChapterList, ...props}) {
  const [advancedChapter, setAdvancedChapter] = useState(0);
  const [restInMinutes, setRestInMinutes] = useState(0);
  const updateAdvancedPlayInterval = useRef(0);

  const getNewAdvanced = useCallback(() => {
    const dateEndMoment = moment.utc(chapter.dateEnd);

    const currentDateMoment = moment().utc();
    let restMinutes = dateEndMoment.diff(currentDateMoment, 'minutes');

    const t =
      dateEndMoment.toDate().getTime() -
      moment.utc(chapter.dateStart).toDate().getTime();

    const f =
      currentDateMoment.toDate().getTime() -
      moment.utc(chapter.dateStart).toDate().getTime();

    const p = (f * 100) / t;

    if (p !== advancedChapter) {
      setAdvancedChapter(p);
      setRestInMinutes(restMinutes);
    }

    const isCurrentChapterEnded = currentDateMoment.isAfter(dateEndMoment);

    if (isCurrentChapterEnded) {
      updateChapterList();
    }

    if (!chapter.isLive) {
      const dateStartMoment = moment.utc(chapter.dateStart);
      if (currentDateMoment.isAfter(dateStartMoment)) {
        updateChapterList();
      }
    }
  }, []);

  useEffect(() => {
    getNewAdvanced();
    updateAdvancedPlayInterval.current = setInterval(getNewAdvanced, 1000);
    return () => {
      clearInterval(updateAdvancedPlayInterval.current);
    };
  }, []);

  return (
    <NavItem
      {...props}
      className={`${styles.chapter} ${chapter.isLive ? styles.active : ''}`}>
      <div className={styles.chapter_side}>
        {chapter.isLive ? (
          <div className={styles.capter_live_ctn}>
            <img
              src="images/live-circle.gif"
              width={30}
              height={30}
              style={{
                width: '30px',
                height: '30px',
              }}
              alt="en vivo"
            />
            <p>AHORA EN VIVO</p>
          </div>
        ) : (
          <div
            style={{
              backgroundImage: `url(${chapter.image})`,
            }}
            className={styles.chapter_image}
            alt="foto del capitulo"
          />
        )}
      </div>
      <div className={styles.chapter_info}>
        <h3 className={styles.chapter_title}>{chapter.title}</h3>
        <p className={styles.chapter_number}>{chapter.chapterNumber}</p>
        {chapter.isLive ? (
          <div>
            <span className={styles.chapter_progresbar_label}>
              {restInMinutes} min restantes
            </span>
            <div className={styles.chapter_progressbar_ctn}>
              <div
                className={styles.chapter_progresbar}
                style={{width: `${advancedChapter}px`}}></div>
            </div>
          </div>
        ) : (
          <p className={styles.chapter_date}>{chapter.date}</p>
        )}
      </div>
    </NavItem>
  );
}
