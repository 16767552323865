import {ACTIONS, API_GET_TOKEN_FOR_API_MEDIA_STREAM} from '@app/constants';
import useChannels from '@app/hooks/useChannels';
import {getUserToken} from '@app/stores/auth';
import styles from '@app/styles/views/Channels.module.sass';
import ChapterChannel from '@components/ChapterChannel';
import NavItem, {
  KEY_NAV,
  keyNavAttribute,
  keyNavSelectors,
} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import MediaPlayer from '@components/VideoPlayer';
import {useSelector} from 'react-redux';
import {useCallback, useEffect, useRef, useState} from 'react';
import {getIsFullScreenPlayer} from '@app/stores/layout';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {trackEvent} from '@app/libs/metrics';

const fetchTokenForMediaStream = async (id, token) => {
  const resp = await fetch(
    `${API_GET_TOKEN_FOR_API_MEDIA_STREAM}live/${id}/${token}`,
  );
  const data = await resp.json();
  return data.access_token;
};

export default function Channels() {
  const isRendered = useRef(false);
  const channels = useChannels();
  const [channelScheduleKeyNavDown, setChannelScheduleKeyNavDown] =
    useState('disabled');
  const [channelScheduleKeyNavUp, setChannelScheduleKeyNavUp] =
    useState('disabled');
  const $channelSchedule = useRef(null);
  const [currentChapterName, setCurrentChapterName] = useState(null);
  const userToken = useSelector(getUserToken);
  const [mediaStreamToken, setMediaStreamToken] = useState(null);
  const isFullScreenPlayer = useSelector(getIsFullScreenPlayer);
  const [playerKeyNavDown, setPlayerKeyNavDown] = useState('disabled');
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(null);
  const [currentChapterList, setCurrentChapterList] = useState(null);
  const lastCurrentTabId = useRef(null);

  const configureChannel = useCallback(
    async tabId => {
      let currentChannel;
      let tokenStream;

      try {
        if (channels.data?.length > 0) {
          if (tabId) {
            currentChannel = channels.data?.find(channel => {
              if (channel?.id === tabId) {
                return true;
              }
              return false;
            });
          } else {
            currentChannel = channels.data[0];
          }
        }
        if (currentChannel?.id) {
          tokenStream = await fetchTokenForMediaStream(
            currentChannel.id,
            userToken,
          );
          let now = moment().valueOf();
          let currentChapter = currentChannel.scheduleJob.find(item => {
            if (
              now > moment(item.dateStart).valueOf() &&
              now < moment(item.dateEnd).valueOf()
            ) {
              return item;
            }
            return false;
          });
          setCurrentChapterName(currentChapter?.name || '');
          setMediaStreamToken(tokenStream);
          setCurrentTab(currentChannel);
          lastCurrentTabId.current = currentChannel.id;
          setCurrentChapterList(currentChannel.scheduleJob);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [channels.data, currentTab, userToken],
  );

  const updateKeyNavChannelSchedule = useCallback(() => {
    const itemwidth =
      document.querySelector('#channels_schedule > div:first-child')
        ?.offsetWidth + 15; // gap;
    const contentwidth = $channelSchedule.current?.offsetWidth;
    let parts = Math.floor(contentwidth / itemwidth);
    let down_args = Array.from({length: parts}, () => 'nextSibling');
    let up_args = Array.from({length: parts}, () => 'previousSibling');

    setChannelScheduleKeyNavDown(
      keyNavSelectors().steps.apply(null, down_args).build(),
    );
    setChannelScheduleKeyNavUp(
      keyNavSelectors()
        .onlyFirstRow(
          `#player [${KEY_NAV.ITEM_LAST_FOCUS}]`,
          `#player [${KEY_NAV.ITEM}]`,
        )
        .steps.apply(null, up_args)
        .build(),
    );
  }, []);

  useEffect(() => {
    if (isFullScreenPlayer) {
      setPlayerKeyNavDown('disabled');
    } else {
      setPlayerKeyNavDown(
        keyNavSelectors()
          .querySelector(
            `#channels_tab [${KEY_NAV.ITEM_LAST_FOCUS}]`,
            `#channels_tab [${KEY_NAV.ITEM}]`,
          )
          .build(),
      );
    }
  }, [isFullScreenPlayer]);

  useEffect(() => {
    updateKeyNavChannelSchedule();
    window.addEventListener('resize', updateKeyNavChannelSchedule);

    return () => {
      window.removeEventListener('resize', updateKeyNavChannelSchedule);
    };
  }, []);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      channels.getData();
    }
    trackEvent({
      action: ACTIONS.PAGE.channelsShowed,
    });
  }, []);

  useEffect(() => {
    if (channels.data?.length > 0) {
      if (currentTab?.id !== params?.channel_id) {
        configureChannel(params?.channel_id);
      } else {
        configureChannel();
      }
    }
  }, [channels.data, userToken, params]);

  return (
    <div className={styles.main}>
      <div
        style={{
          position: 'relative',
          height: '58vh',
          backgroundColor: '#000',
        }}>
        <div
          id="player"
          className={`inline ${
            isFullScreenPlayer ? styles.fullscreen_main : ''
          }`}>
          {mediaStreamToken && currentTab?.source && (
            <MediaPlayer
              title={`${currentTab?.title} - ${currentChapterName}`}
              isLive={true}
              isChannel={true}
              mediaId={currentTab?.id}
              mediaToken={mediaStreamToken}
              keynavdown={playerKeyNavDown}
              keynavleft={keyNavSelectors()
                .steps('previousSibling')
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
          )}
        </div>
      </div>
      <div className={styles.channel_ctn}>
        <div className={styles.tab_ctn}>
          <div
            id="channels_tab"
            className={styles.tab_list}
            {...keyNavAttribute(KEY_NAV.PARENT)}>
            {channels?.data?.map((item, index) => (
              <NavItem
                key={item?.id + ' ' + index}
                onClick={() => {
                  if (item?.id !== lastCurrentTabId.current) {
                    configureChannel(item?.id);
                  }
                  trackEvent({
                    action: ACTIONS.PAGE.channelClicked,
                    label: item.title,
                  });
                }}
                className={`${styles.tab} ${
                  currentTab?.id === item?.id ? styles.active : ''
                }`}
                keynavright={keyNavSelectors()
                  .querySelector(
                    `#channels_schedule [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#channels_schedule [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .querySelector(
                    `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu-main [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavup={keyNavSelectors()
                  .onlyIndex0(
                    `#player [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#player [${KEY_NAV.ITEM}]`,
                  )
                  .steps('previousSibling')
                  .build()}
                keynavdown={keyNavSelectors().steps('nextSibling').build()}>
                <span>
                  {{AFHS: 'Al Fondo Hay Sitio'}[item?.title] || item?.title} »
                </span>
              </NavItem>
            ))}
          </div>
        </div>
        <div
          ref={$channelSchedule}
          id="channels_schedule"
          className={styles.chapter_ctn}
          {...keyNavAttribute(KEY_NAV.PARENT)}
          {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
          {isLoading && (
            <div style={{width: '100%'}}>
              <Loading style={{}} />
            </div>
          )}
          {!isLoading && (
            <>
              {currentChapterList.map((chapter, index) => (
                <ChapterChannel
                  key={`${chapter?.dateStart}-${chapter?.dateEnd}-${index}`}
                  title={chapter?.showName}
                  name={chapter?.name}
                  image={`https:${chapter?.images[0]}?auto=format&w=119&h=66`}
                  dateEnd={chapter?.dateEnd}
                  dateStart={chapter?.dateStart}
                  date={moment(chapter?.dateStart).format('HH:MM A')}
                  keynavleft={keyNavSelectors()
                    .borderLeft(
                      `#channels_tab [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#channels_tab [${KEY_NAV.ITEM}]`,
                    )
                    .steps('previousSibling')
                    .build()}
                  keynavright={keyNavSelectors()
                    .steps('nextSibling')
                    .borderRight('disabled')
                    .build()}
                  keynavdown={channelScheduleKeyNavDown}
                  keynavup={channelScheduleKeyNavUp}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
