import {fetchApi} from '@app/api/repository';
import {API_MEDIA_STREAM, API_MEDIA_STREAM_TOKEN} from '@app/constants';
import {useState} from 'react';

export default function useChannels() {
  const [channels, setChannels] = useState(null);

  /**
   * Asynchronously fetches data from the API endpoint and returns the result.
   *
   * @return {Promise<Object|null>} The data fetched from the API endpoint, or null if an error occurred.
   */
  async function getData() {
    try {
      const response = await fetchApi(
        `${API_MEDIA_STREAM}live-stream?token=${API_MEDIA_STREAM_TOKEN}&populate_episode=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const result = await response.json();
      return result?.data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Asynchronously fetches the schedule for a given live stream ID.
   *
   * @param {string} id - The ID of the live stream to fetch the schedule for.
   * @return {Promise<Object|null>} A promise that resolves to the schedule data, or null if an error occurred.
   */
  async function getSchedule(id) {
    try {
      const response = await fetchApi(
        `${API_MEDIA_STREAM}live-stream/${id}/schedule-job?token=${API_MEDIA_STREAM_TOKEN}&populate_episode=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const result = await response.json();
      return result?.data;
    } catch (e) {
      return null;
    }
  }

  return {
    channels,
    getData,
    getSchedule,
  };
}
