import {API_GET_TOKEN_FOR_API_MEDIA_STREAM} from '@app/constants';
import useChannels from '@app/hooks/useChannels';
import {getUserToken} from '@app/stores/auth';
import styles from '@app/styles/views/Channels.module.sass';
import ChapterChannel from '@components/ChapterChannel';
import NavItem, {
  KEY_NAV,
  keyNavAttribute,
  keyNavSelectors,
} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import MediaPlayer from '@components/VideoPlayer';
import {useSelector} from 'react-redux';
import {useCallback, useEffect, useRef, useState} from 'react';
import {getIsFullScreenPlayer} from '@app/stores/layout';
import {useParams} from 'react-router-dom';

const fetchTokenForMediaStream = async (id, token) => {
  const resp = await fetch(
    `${API_GET_TOKEN_FOR_API_MEDIA_STREAM}live/${id}/${token}`,
  );
  const data = await resp.json();
  return data.access_token;
};

export default function Channels() {
  const isRendered = useRef(false);
  const [channelSchedule, setChannelSchedule] = useState([]);
  const channels = useChannels();
  const [tabs, setTabs] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(-1);
  const [channelScheduleKeyNavDown, setChannelScheduleKeyNavDown] =
    useState('disabled');
  const [channelScheduleKeyNavUp, setChannelScheduleKeyNavUp] =
    useState('disabled');
  const $channelSchedule = useRef(null);
  const [isLoadingChannelSchedule, setIsLoadingChannelSchedule] =
    useState(true);
  const [currentChapterName, setCurrentChapterName] = useState(null);
  const userToken = useSelector(getUserToken);
  const [mediaStreamToken, setMediaStreamToken] = useState(null);
  const isFullScreenPlayer = useSelector(getIsFullScreenPlayer);
  const [playerKeyNavDown, setPlayerKeyNavDown] = useState('disabled');
  const params = useParams();

  const handleTabChannel = index => {
    setCurrentTabIndex(index);
  };

  const filterChannelsAvailable = useCallback(async () => {
    try {
      const data = await channels.getData();
      const AFHS = data.find(item => item.id === '657fbe15e3ba9363bb07c6b4');
      const NOVELAS = data.find(item => item.id === '65fc43190d37be36e84e931f');

      AFHS.name = 'Al fondo hay sitio';
      NOVELAS.name = 'Novelas';
      setTabs([AFHS, NOVELAS]);
      if (currentTabIndex === -1) {
        if (params?.channel_id) {
          if (NOVELAS.id === params?.channel_id) {
            setCurrentTabIndex(1);
          } else {
            setCurrentTabIndex(0);
          }
        } else {
          setCurrentTabIndex(0);
        }
      }
    } catch (e) {
      console.log('filterChannelsAvailable error', e);
    }
  }, [currentTabIndex]);

  const getChannelSchedule = useCallback(async id => {
    try {
      setIsLoadingChannelSchedule(true);
      const data = await channels.getSchedule(id);
      const tokenStream = await fetchTokenForMediaStream(id, userToken);

      setMediaStreamToken(tokenStream);

      if (data?.length > 0) {
        setChannelSchedule(data);
        let current = data.find(item => item.is_current);
        setCurrentChapterName(current?.name);
        setTimeout(updateKeyNavChannelSchedule, 500);
      } else {
        setCurrentChapterName(null);
        setChannelSchedule([]);
      }
    } catch (e) {
      console.log('getChannelSchedule error', e);
    } finally {
      setIsLoadingChannelSchedule(false);
    }
  });

  const updateKeyNavChannelSchedule = useCallback(() => {
    const itemwidth =
      document.querySelector('#channels_schedule > div:first-child')
        ?.offsetWidth + 15; // gap;
    const contentwidth = $channelSchedule.current?.offsetWidth;
    let parts = Math.floor(contentwidth / itemwidth);
    let down_args = Array.from({length: parts}, () => 'nextSibling');
    let up_args = Array.from({length: parts}, () => 'previousSibling');

    setChannelScheduleKeyNavDown(
      keyNavSelectors().steps.apply(null, down_args).build(),
    );
    setChannelScheduleKeyNavUp(
      keyNavSelectors()
        .onlyFirstRow(
          `#player [${KEY_NAV.ITEM_LAST_FOCUS}]`,
          `#player [${KEY_NAV.ITEM}]`,
        )
        .steps.apply(null, up_args)
        .build(),
    );
  }, []);

  const reloadChannelSchedule = useCallback(() => {
    getChannelSchedule(tabs[currentTabIndex].id);
  }, [currentTabIndex, getChannelSchedule, tabs]);

  useEffect(() => {
    if (isFullScreenPlayer) {
      setPlayerKeyNavDown('disabled');
    } else {
      setPlayerKeyNavDown(
        keyNavSelectors()
          .querySelector(
            `#channels_tab [${KEY_NAV.ITEM_LAST_FOCUS}]`,
            `#channels_tab [${KEY_NAV.ITEM}]`,
          )
          .build(),
      );
    }
  }, [isFullScreenPlayer]);

  useEffect(() => {
    updateKeyNavChannelSchedule();
    window.addEventListener('resize', updateKeyNavChannelSchedule);

    return () => {
      window.removeEventListener('resize', updateKeyNavChannelSchedule);
    };
  }, []);

  useEffect(() => {
    if (tabs[currentTabIndex]?.id) {
      getChannelSchedule(tabs[currentTabIndex].id);
    }
  }, [currentTabIndex]);

  useEffect(() => {
    if (!isRendered.current) {
      filterChannelsAvailable();
      isRendered.current = true;
    }
  }, []);

  return (
    <div className={styles.main}>
      <div
        style={{
          position: 'relative',
          height: '58vh',
          backgroundColor: '#000',
        }}>
        <div
          id="player"
          className={`inline ${
            isFullScreenPlayer ? styles.fullscreen_main : ''
          }`}>
          {mediaStreamToken ? (
            <MediaPlayer
              title={`${tabs[currentTabIndex]?.name || ''} - ${
                currentChapterName || ''
              }`}
              isLive={true}
              isChannel={true}
              mediaId={tabs[currentTabIndex]?.id}
              mediaToken={mediaStreamToken}
              keynavdown={playerKeyNavDown}
              keynavleft={keyNavSelectors()
                .steps('previousSibling')
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <div className={styles.channel_ctn}>
        <div className={styles.tab_ctn}>
          <div
            id="channels_tab"
            className={styles.tab_list}
            {...keyNavAttribute(KEY_NAV.PARENT)}>
            {tabs.map((tab, index) => (
              <NavItem
                key={tab?.id}
                onClick={() => handleTabChannel(index)}
                className={`${styles.tab} ${
                  currentTabIndex === index ? styles.active : ''
                }`}
                keynavright={keyNavSelectors()
                  .querySelector(
                    `#channels_schedule [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#channels_schedule [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .querySelector(
                    `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu-main [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavup={keyNavSelectors()
                  .onlyIndex0(
                    `#player [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#player [${KEY_NAV.ITEM}]`,
                  )
                  .steps('previousSibling')
                  .build()}
                keynavdown={keyNavSelectors().steps('nextSibling').build()}>
                <span>{tab?.name + ' »'}</span>
              </NavItem>
            ))}
          </div>
        </div>
        <div
          ref={$channelSchedule}
          id="channels_schedule"
          className={styles.chapter_ctn}
          {...keyNavAttribute(KEY_NAV.PARENT)}
          {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
          {isLoadingChannelSchedule && (
            <div style={{width: '100%'}}>
              <Loading style={{}} />
            </div>
          )}
          {!isLoadingChannelSchedule && (
            <>
              {channelSchedule.map((chapter, index) => (
                <ChapterChannel
                  key={chapter?.id}
                  chapter={{
                    title: chapter?.show_info?.showId?.title,
                    image: chapter?.show_info?.episode?.images[0]?.path,
                    isLive: chapter?.is_current,
                    chapterNumber: chapter?.name,
                    dateEnd: chapter?.date_end,
                    dateStart: chapter?.date_start,
                  }}
                  updateChapterList={reloadChannelSchedule}
                  keynavleft={keyNavSelectors()
                    .borderLeft(
                      `#channels_tab [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#channels_tab [${KEY_NAV.ITEM}]`,
                    )
                    .steps('previousSibling')
                    .build()}
                  keynavright={keyNavSelectors()
                    .steps('nextSibling')
                    .borderRight('disabled')
                    .build()}
                  keynavdown={channelScheduleKeyNavDown}
                  keynavup={channelScheduleKeyNavUp}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
